<template>
  <div class="mw8 center">
    <h1>
      Insufficient Permissions
    </h1>
    <p>
      You do not have the required permissions to access this page.
    </p>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
